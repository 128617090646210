import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BACKEND_DATE_FORMAT,
  ProjectStatus,
} from '@symfa-inc/providence-verizon-types';
import { Form } from 'antd';
import { Store } from 'antd/es/form/interface';
import classNames from 'classnames';
import { ProjectInformation } from '@client/pages/project/components';
import { HttpService, ObjectComparatorService } from '@core/services';
import { ProjectHttpService } from '@core/services/http';
import { dateWorker } from '@core/utils/date-worker';
import { useDidUpdateEffect, useSaveChanged } from '@core/utils/hooks';
import {
  formDiffs,
  isValidForm,
  replaceUndefinedWithNull,
} from '@core/utils/methods';
import {
  FormProps,
  ProjectFuzeID as ProjectFuzeIDInterface,
  ProjectUpdateRequestData,
} from '@models/interfaces';
import {
  getPaceTaskDifference,
  NotificationsLoader,
  paceTaskMapper,
  PaceTaskTable,
  Panel,
  ProjectFuzeID,
} from '@shared/components';
import { PrimaryButton, ShowMoreInput } from '@shared/modules';
import {
  CommonActions,
  ProjectActions,
  ProjectFuzeIDsActions,
} from '@store/actions';
import {
  PaceTasksSelectors,
  ProjectSelectors,
  UserSelectors,
} from '@store/selectors';
import { DeleteProjectModal, HoldReasonModal } from './components';
import { ProjectInfo } from './models';

import './styles.scss';

const { useForm } = Form;

export const ProjectSummary: FC<FormProps> = ({
  isEditing = false,
  history,
  toggleEditing,
  permissions: { canRemoveProject, tableAccess, projectInformationFields },
}: PropsWithChildren<FormProps>) => {
  const projectService = useMemo(
    () => HttpService.getHttpRequests(ProjectHttpService),
    [],
  );

  const dispatch = useDispatch();

  const [form] = useForm();
  const [paceTaskForm] = useForm();
  const [notesForm] = useForm();

  const {
    currentProject,
    projectPaceTasks,
    projectSite,
    notes: { engineeringNote, leasingNote, siteNotes, projectPermittingNote },
    currentProjectFuzeIDs,
    originalProjectFuzeIDs,
    projectFuzeIDsTableVisible,
  } = useSelector(ProjectSelectors.getProjectSummaryData);
  const arePaceTasksChanged = useSelector(
    PaceTasksSelectors.arePaceTasksChanged,
  );
  const roles = useSelector(UserSelectors.getUserRoles);

  const [holdReasonModalVisible, setReasonModalVisible] =
    useState<boolean>(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [projectInfo, setProjectInfo] = useState<ProjectInfo>({
    ...currentProject,
    siteId: projectSite.id,
  });
  const [objectsCompare, setObjectsCompare] = useState<boolean>();
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);

  const buttonsDisabled =
    submitDisabled || (objectsCompare !== false && !arePaceTasksChanged);

  useEffect(
    () =>
      setProjectInfo({
        ...currentProject,
        siteId: projectSite.id,
      }),
    [currentProject, projectSite],
  );

  const toggleDeleteModalVisible = (): void =>
    setDeleteModalVisible((prevState: boolean) => !prevState);

  const projectCompare = (project: Store): void => {
    setObjectsCompare(
      ObjectComparatorService.objectsCompare(
        project,
        {
          ...currentProject,
          siteId: projectSite.id,
        },
        true,
      ) &&
        ObjectComparatorService.arraysCompare(
          currentProjectFuzeIDs,
          originalProjectFuzeIDs,
        ),
    );
  };

  const getProjectData = (): Omit<ProjectUpdateRequestData, 'site'> => {
    const fuzeIDs = currentProjectFuzeIDs.map(
      (fuzeID: ProjectFuzeIDInterface) => {
        const clonedFuzeID = fuzeID;
        const originalFuzeID =
          originalProjectFuzeIDs.find(
            ({ generatedId }) => generatedId === fuzeID.generatedId,
          ) || {};

        delete clonedFuzeID.generatedId;
        delete originalFuzeID.generatedId;

        const { projectAssignedDate, ...restValues } = clonedFuzeID;

        const fuzeIDsyWithoutUndefinedId = {
          ...(originalFuzeID.id ? { id: originalFuzeID.id } : {}),
          ...formDiffs(originalFuzeID, {
            ...restValues,
            projectAssignedDate: projectAssignedDate
              ? dateWorker(projectAssignedDate).format(BACKEND_DATE_FORMAT)
              : undefined,
          }),
        };

        if (fuzeIDsyWithoutUndefinedId.id === undefined) {
          delete fuzeIDsyWithoutUndefinedId.id;
        }

        return fuzeIDsyWithoutUndefinedId;
      },
    );

    return {
      fuzeIDs,
      ...formDiffs<any>(currentProject, {
        ...currentProject,
        ...projectInfo,
      }),
    };
  };

  const onSubmit = async (): Promise<void> => {
    const [projectPaceTaskData, keys] = getPaceTaskDifference(
      projectPaceTasks,
      paceTaskForm,
      roles,
    );

    if ((await isValidForm(form)) && (await isValidForm(paceTaskForm, keys)))
      try {
        setSubmitDisabled(true);

        const projectData = getProjectData();

        const processedData = replaceUndefinedWithNull(projectData);

        await projectService.updateProject(currentProject?.id, {
          ...processedData,
          projectPaceTaskData,
        } as ProjectUpdateRequestData);

        toggleEditing?.();

        setObjectsCompare(true);
        dispatch(ProjectActions.setArePACETasksChanged.done(false));

        NotificationsLoader.notificationSuccess('Project has been updated!');
      } catch (e) {
        console.error(e);
      }

    setSubmitDisabled(false);
  };

  const onDelete = async (): Promise<void> => {
    try {
      await projectService.deleteProject(currentProject?.id);

      NotificationsLoader.notificationSuccess('Project has been deleted!');

      dispatch(ProjectFuzeIDsActions.setProjectFuzeIDsAction.done([]));
    } catch (e) {
      console.error(e);
    }

    history?.push('/project/browse');
  };

  const onCancel = (): void => {
    dispatch(
      ProjectFuzeIDsActions.setProjectFuzeIDsAction.done(
        originalProjectFuzeIDs,
      ),
    );
    setProjectInfo({
      ...currentProject,
      siteId: projectSite.id,
    });
    paceTaskForm.setFieldsValue(paceTaskMapper(projectPaceTasks));

    setObjectsCompare(true);
    dispatch(ProjectActions.setArePACETasksChanged.done(false));

    toggleEditing?.();
  };

  useEffect(
    () => (): void => {
      dispatch(CommonActions.setHasUnsubmittedData.done(false));
    },
    [],
  );

  useEffect(() => {
    projectCompare(projectInfo);
  }, [currentProjectFuzeIDs]);

  useDidUpdateEffect(() => {
    dispatch(
      CommonActions.setHasUnsubmittedData.done(
        [arePaceTasksChanged, !objectsCompare].some((item: boolean) => item),
      ),
    );
  }, [arePaceTasksChanged, objectsCompare]);

  useSaveChanged(isEditing, onSubmit, onCancel);

  const onProjectInfoChange = (): void => {
    const project = form.getFieldsValue();

    if (
      projectInfo.projectStatus !== ProjectStatus.Hold &&
      project.projectStatus === ProjectStatus.Hold
    ) {
      setReasonModalVisible(true);
      project.projectStatus = projectInfo.projectStatus;
      form.setFieldsValue({
        projectStatus: projectInfo.projectStatus,
      });
    }

    projectCompare(project);
    setProjectInfo(project as ProjectInfo);
  };

  const onOkHoldReasonHandle = (holdReason: string): void => {
    const project = {
      ...projectInfo,
      projectStatus: ProjectStatus.Hold,
      holdReason,
    };

    projectCompare(project);

    setProjectInfo(project);
    form.setFieldsValue({ projectStatus: ProjectStatus.Hold, holdReason });
    setReasonModalVisible(false);
  };

  return (
    <div className="prov-project-summary">
      <ProjectFuzeID editable={tableAccess} />
      <div
        className={classNames('tabs-wrap', {
          'tabs-wrap_with-actions': isEditing,
          'fuze-ids-table-open': projectFuzeIDsTableVisible,
        })}
      >
        <div className="prov-project-summary__wrap">
          <div className="info-panel">
            <ProjectInformation
              className="info-panel__information"
              isEditing={isEditing}
              permissions={projectInformationFields}
              colSpan="24"
              form={form}
              data={projectInfo}
              onChange={onProjectInfoChange}
            />
            <Form
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 14 }}
              className="info-panel__notes"
              form={notesForm}
            >
              <Panel header="Notes">
                <ShowMoreInput
                  id="siteNotes"
                  label="Site Notes"
                  data={siteNotes}
                  form={notesForm}
                  isEditing={false}
                />
                <ShowMoreInput
                  id="engineeringNote"
                  label="Engineering Notes"
                  data={engineeringNote}
                  form={notesForm}
                  isEditing={false}
                />
                <ShowMoreInput
                  id="leasingNote"
                  label="Leasing Notes"
                  data={leasingNote}
                  form={notesForm}
                  isEditing={false}
                />
                <ShowMoreInput
                  id="projectPermittingNote"
                  label="Project Permitting Notes"
                  data={projectPermittingNote}
                  form={notesForm}
                  isEditing={false}
                />
              </Panel>
            </Form>
          </div>
          <PaceTaskTable
            isEditing={isEditing}
            data={projectPaceTasks}
            form={paceTaskForm}
          />
        </div>
      </div>
      {isEditing && (
        <div className="prov-project-summary__btns">
          <div className="action-wrap">
            <PrimaryButton
              title="Submit"
              disabled={buttonsDisabled}
              onClick={onSubmit}
            />
            <PrimaryButton
              title="Cancel"
              type="default"
              disabled={buttonsDisabled}
              onClick={onCancel}
            />
          </div>
          {canRemoveProject && (
            <PrimaryButton
              className="danger-btn"
              title="Delete the project"
              type="danger"
              onClick={toggleDeleteModalVisible}
            />
          )}
        </div>
      )}
      <DeleteProjectModal
        visible={deleteModalVisible}
        onCancel={toggleDeleteModalVisible}
        onOk={onDelete}
      />
      <HoldReasonModal
        visible={holdReasonModalVisible}
        onOk={onOkHoldReasonHandle}
        onCancel={(): void => setReasonModalVisible(false)}
      />
    </div>
  );
};
